import React from 'react'
import Layout from '../../components/layout/Layout';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentPratica from '../../components/ContentPratica';
import { LeftIntestazioneTemporanea, RightIntestazioneTemporanea } from '../../components/pratiche-auto/intestazione/Intestazione';
import { LeftCollaudiRevisions, RightCollaudiRevisions } from '../../components/pratiche-auto/collaudi-revisioni/CollaudiRevisioni';

const CollaudiRevisioni = () => {
    return (
        <Layout>
            <PageNavigator link="/pratiche-auto" text="Pratiche auto" />
            <ContentPratica id="collaudi-revisioni" title="Collaudi e revisioni">
                <LeftCollaudiRevisions />
                <RightCollaudiRevisions/>

            </ContentPratica>
        </Layout>
    )
}

export default CollaudiRevisioni
